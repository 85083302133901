import React from "react";
import { useHistory } from "react-router";
import { Table, Avatar, Image, Space, Button, Popconfirm, Typography } from "antd";

import _ from "lodash";

import "./styles.css";

const MenuItemTable = (props) => {
  const {
    tableData,
    status,
    NO_IMAGE,
    setMenuToClone,
    menuToDelete,
    setMenuToDelete,
    setCloneMenuModalVisible,
    handleDeletePopConfirmOk,
    handleDeletePopConfirmCancel,
    setSearchPattern,
  } = props;

  const { Text } = Typography;

  const history = useHistory();

  const data = _.map(tableData, (menu) => {
    const rawDescription = _.get(menu, "description", "");
    const description = rawDescription ? `(${rawDescription})` : "";
    return {
      id: menu.id,
      key: menu.id,
      logo: _.get(menu, "brand.logo.url", NO_IMAGE),
      title: menu.title,
      description: description,
    };
  });

  data.sort((a, b) => {
    let titleA = a.title ? a.title.toLowerCase() : null;
    let titleB = b.title ? b.title.toLowerCase() : null;
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  const columns = [
    {
      title: "logo",
      dataIndex: "logo",
      key: "logo",
      width: "10%",
      render: (imageUrl) => {
        return (
          <Avatar
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid rgb(235, 237, 240)",
            }}
            src={<Image style={{ height: 35, width: 35 }} src={imageUrl} preview={false} fallback={NO_IMAGE} />}
            shape="circle"
            size="default"
          />
        );
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "60%",
      ellipsis: {
        showTitle: false,
      },
      render: (title, record) => <Text strong={true}>{title} {record.description}</Text>,
    },
    {
      title: "action",
      key: "action",
      align: "right",
      width: "30%",
      render: (menu) => (
        <Space size="small">
          <Button
            type="link"
            onClick={() => {
              const id = _.get(menu, "id");
              history.push(`/menu/${id}`);
              setSearchPattern("");
            }}
          >
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => {
              setMenuToClone(menu);
              setCloneMenuModalVisible(true);
            }}
          >
            Clone
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${_.get(menu, "title")}?`}
            visible={menuToDelete && menuToDelete.id === menu.id ? true : false}
            onConfirm={() => handleDeletePopConfirmOk(menu)}
            onCancel={handleDeletePopConfirmCancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" onClick={() => setMenuToDelete(menu)} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="menu-table-wrapper">
      <Table
        size="small"
        className="menu-table"
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={{ position: ["bottomCenter"], defaultPageSize: 10 }}
        loading={status === "pending" ? true : false}
      />
    </div>
  );
};

export default MenuItemTable;

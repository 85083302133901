import request from "./request";
import * as gql from "gql-query-builder";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { strapiWithAuth } from "./strapiWithAuth";
import _ from "lodash";

const STRAPI_BASE_DEVELOPMENT = "http://localhost:1337";

const STRAPI_BASE_SANDBOX = "https://menusystemsandbox.k8s.alldaykitchens.com";

const STRAPI_BASE_PROD = "https://menu.k8s.alldaykitchens.com";

export const STRAPI_BASE = window.location.hostname === "localhost"
  ? STRAPI_BASE_DEVELOPMENT
  : window.location.hostname.includes("sandbox")
  ? STRAPI_BASE_SANDBOX
  : STRAPI_BASE_PROD;

export function login(endpoint, headers = {}) {
  const url = `${STRAPI_BASE}/${endpoint}`;
  return request(url, {
    method: "GET",
    headers,
  });
}

export function getStrapi(endpoint, headers = {}) {
  const url = `${STRAPI_BASE}/${endpoint}`;
  return strapiWithAuth(url, {
    method: "GET",
    headers,
  });
}


export function createStrapi(endpoint, body, headers = {}) {
  const url = `${STRAPI_BASE}/${endpoint}`;
  return strapiWithAuth(url, {
    method: "POST",
    headers,
    body,
  });
}

export function updateStrapi(endpoint, body, headers = {}) {
  const url = `${STRAPI_BASE}/${endpoint}`;
  return strapiWithAuth(url, {
    method: "PUT",
    headers,
    body,
  });
}

export function deleteStrapi(endpoint, headers = {}) {
  const url = `${STRAPI_BASE}/${endpoint}`;
  return strapiWithAuth(url, {
    method: "DELETE",
    headers,
  });
}

export function loadBrands() {
  return getStrapi("brands");
}

// graphql apis
export function duplicateMenu(menuId, menuTitle, brandId) {
  const url = `${STRAPI_BASE}/graphql`;
  const mutation = {
    duplicateMenu: {
      __args: {
        menuId,
        menuTitle,
        brandId,
      },
      id: true,
      title: true,
    },
  };
  const query = jsonToGraphQLQuery({ mutation });
  return strapiWithAuth(url, {
    method: "POST",
    body: {
      query,
    },
  });
}
export function loginWithUserName(body) {
  const url = `${STRAPI_BASE}/auth/local`;
  return request(url, {
    method: "POST",
    headers: {},
    body,
  });

}

export function updateSort(updatedData, updateType) {
  const url = `${STRAPI_BASE}/graphql`;
  const mutation = {};
  let index = 0;
  const updateOperationName = _.camelCase(`update ${updateType}`);
  for (const data of updatedData) {
    index++;
    mutation[`update` + index] = {
      __aliasFor: updateOperationName,
      __args: {
        input: {
          where: { id: data.id },
          data: { sort: data.sort },
        },
      },
      [updateType]: {
        id: true,
        sort: true,
      },
    };
  }
  const query = jsonToGraphQLQuery({ mutation });
  return strapiWithAuth(url, {
    method: "POST",
    body: {
      query,
    },
  });
}

export function getMenus() {
  const url = `${STRAPI_BASE}/graphql`;
  const body = gql.query({
    operation: "menus",
    fields: [
      "id",
      "title",
      "active",
      "description",
      {
        brand: [
          {
            logo: ["url"],
          },
        ],
      },
    ],
  });
  return request(url, {
    method: "POST",
    body,
  });
}

export function getMenu(id) {
  const url = `${STRAPI_BASE}/graphql`;
  const body = gql.query({
    operation: "menu",
    variables: {
      id: {
        type: "ID!",
        value: id,
      },
    },
    fields: [
      "id",
      "title",
      "description",
      "hours",
      "published_at",
      "created_at",
      "updated_at",
      "targets",
      {
        brand: [
          "id",
          "name",
          {
            logo: ["formats"],
          },
        ],
      },
      {
        internal_promos: [
          "id",
          "name",
          "type",
          "targets",
          "rule",
          "expiry_time",
          "voided_at",
          {
            sku:[
              "name"
            ]
          }
        ],
      }
    ],
  });
  return request(url, {
    method: "POST",
    body,
  });
}

export function getMenuSkus(menuId) {
  const url = `${STRAPI_BASE}/graphql`;
  const body = gql.query({
    operation: "menu",
    variables: {
      id: {
        type: "ID!",
        value: menuId,
      },
    },
    fields: [
      {
        menu_items: [
          "id",
          "name",
          {
            skus: [
              "id",
              "name",
              "active"
            ],
          },
        ],
      },
    ],
  });
  return request(url, {
    method: "POST",
    body,
  });
}
